


function lhd_grss(plhd,plscs) {
  
    let w_lscs =plscs.filter((item) => {
        if (item.sotr == plhd.sotr && item.god == plhd.god && item.mec == plhd.mec)
            return true
        else
            return false
    })

    let srows = w_lscs.map((ar) => {
        return ar.grs
    })
    return srows
}
export default lhd_grss;