import logo from './logo.svg';
import './App.css';
import Lhds_t from './Lhds_t.js';

import { useState } from 'react';
import List_sotr from './List_sotr.js';
import Lhd_sotr from './Lhd_sotr.js';
import List_grs from './List_grs.js';
import mec_name from './mec_name.js'

import grs from './grs.json';
import lsc from './lsc.json';
import sotr from './sotr.json';
import prof from './prof.json';
import lhd from './lhd.json';
import { Context } from './Context.js';

function App(props) {
  const [sgrs, Setsgrs] = useState(0)
  const [spass, Setspass] = useState("1441")
  const [ssotr, Setssotr] = useState(0)

  let snbase = 'soc';

  let sotrs = []; let grss = []; let profs = []; let lhds = []; let lscs = [];

  grss = Array.from(grs)
  grss.sort(function (a, b) {
    if (a.sq > b.sq)
      return 1;
    else
      return -1;
  })
  lscs = Array.from(lsc)
  lhds = Array.from(lhd)
  profs = Array.from(prof)
  sotrs = Array.from(sotr)


  for (let i = 0; i < lhds.length; i++) { // выведет 0, затем 1, затем 2
    if (!("kopb" in lhds[i]))
      lhds[i].kopb = "0"

    lhds[i].mec.padStart(2, "0")
    lhds[i].fio = ""
    for (let z = 0; z < sotrs.length; z++) { // выведет 0, затем 1, затем 2
      if (sotrs[z].kod === lhds[i].sotr) {
        lhds[i].fio = sotrs[z].fio
        break
      }
    }
    lhds[i].dolg = "";
    if (lhds[i].prof > 0)
      for (let f = 0; f < profs.length; f++) { // выведет 0, затем 1, затем 2
        if (profs[f].kod === lhds[i].prof) {
          lhds[i].dolg = profs[f].name
          break
        }
      }
    else
      lhds[i].dolg = "asssssss"
  }

  let w_grs = {}
  for (let i = 0; i < lscs.length; i++) { // выведет 0, затем 1, затем 2

    for (let z = 0; z < grss.length; z++) { // выведет 0, затем 1, затем 2
      if (grss[z].kod === lscs[i].grs) {
        w_grs = grss[z]
        break
      }
    }
    lscs[i].ngrs = w_grs.name
    lscs[i].name = w_grs.name
    if (lscs[i].ndog && lscs[i].nach) {
      lscs[i].name += ' ' + lscs[i].ndog + ' от ' + lscs[i].ddog
    }
    if ('bdog' in lscs[i] && 'edog' in lscs[i] && lscs[i].nach) {
      let w_bdog = lscs[i].bdog.split('/')
      let w_edog = lscs[i].edog.split('/')
      if (w_bdog[1] === w_edog[1])
        lscs[i].name += ' c ' + w_bdog[0] + ' по ' + w_edog[0] + ' ' + mec_name(w_bdog[1])
    }

    if (lscs[i].nach === "" && lscs[i].uder === "" && lscs[i].sumr !== "")
      lscs[i].name += ' ' + lscs[i].sumr + ' руб'
    if (lscs[i].rab)
      lscs[i].name += ' ' + w_grs.np1 + ' ' + lscs[i].rab
    if (lscs[i].rabt)
      lscs[i].name += ' ' + w_grs.np2 + ' ' + lscs[i].rabt

    if (lscs[i].mec !== lscs[i].mecb)
      lscs[i].name += '///' + mec_name(lscs[i].mecb) + ' ' + lscs[i].godb

  }

  if ((snbase == "su3" && spass !== "1221")
    || (snbase == "su2" && spass !== "1331")
    || (snbase == "soc" && spass !== "1441"))
    return (
      <div className="App" >
        <label>    Пароль <input value={spass} onChange={e => { Setspass(e.target.value) }} /> {" "}</label>
      </div>
    )
  else {
    if ("sotr" in props && props.sotr > 0) {
      let w_sotr = {}
      for (let z = 0; z < sotrs.length; z++) { // выведет 0, затем 1, затем 2
        if (sotrs[z].kod === props.sotr) {
          w_sotr = sotrs[z]
          w_sotr.god = "2024"
          w_sotr.sotr = props.sotr
          //alert(Object.keys(w_sotr))
          break
        }
      }
      return (

        <div>
          <Context.Provider value={w_sotr}>
            <Lhd_sotr lhds={lhds} lscs={lscs} />
          </Context.Provider>
        </div >
      )
    }
    return (
      <div>
        <div style={{ "display": "flex" }}>
          <List_sotr Setssotr={Setssotr} sotr={sotrs} />
          <List_grs Setsgrs={Setsgrs} grs={grss} />
        </div>
        <Lhds_t sotr={ssotr} grs={sgrs} Setssotr={Setssotr} lscs={lscs} lhds={lhds} />
      </div >
    )
  }
}

export default App;

// var request = new XMLHttpRequest;

// request.open('GET', 'us_quantity.txt', true);

// request.onload = function () {
//     console.log(request.responseText);
// };

// request.send(null);
