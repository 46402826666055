import React from 'react'

function List_grs(props) {
    


    let s_grss = props.grs.map(grs => {
      
            return  <option key={grs.kod} value={grs.kod}>{grs.sq + ' ' + grs.name}</option>

    })
    
    return (
            <div >
                <select id="list_grs" onChange={(e) => {props.Setsgrs(e.target.value)}}>
                
                <option key="0" value="0" >Все операции</option>
                    {s_grss}
                </select>
            </div>
    )

}

export default List_grs;