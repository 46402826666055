import React from 'react'
import { useState } from 'react';

import lhd_grss from './lhd_grss.js';

import Lhd_sotr from './Lhd_sotr.js';
import Lhd_nmag from './Lhd_nmag.js';
import { Context } from './Context.js';
import './lhds_t.css';

function Lhds_t(props) {

  const [slhd, Setslhd] = useState({})
  const [nsort, Setnsort] = useState(1)
  const [sfio, Setsfio] = useState("")
  const [sdolg, Setsdolg] = useState("")
  const [sgod, Setsgod] = useState("2024")
  const [smec, Setsmec] = useState("6")
  const [smag, Setsmag] = useState("")

  //alert('grs='+props.grs +' sotr='+props.sotr)
  let cnt=0
  let w_sotr;
  let m_lhds = []; m_lhds.length = 0
  let w_lhds = props.lhds.filter(function (ar,index) {
    
    if ((sgod == 0 || sgod == ar.god)
      && (smec == 0 || smec == ar.mec)
      && ((props.sotr == 0) || (props.sotr == ar.sotr))
      && ((smag == "") || ar.nmag.includes(smag))
      && ((sdolg == "") || ar.dolg.includes(sdolg))
      && ((sfio == "") || ar.fio.includes(sfio))
      && ((props.grs == 0) || lhd_grss(ar, props.lscs).includes(props.grs))   //  возвращает массив операций расч листка
    ) {
      if (!(m_lhds.includes(ar.nmag))) {
        m_lhds.push(ar.nmag)
      }
      cnt++
      return true
    }
    else
      return false
  })

  //alert('w_lhds.length=' + w_lhds.length)

  for (let i = 0; i < m_lhds.length; i++)
    w_lhds.push({ "sotr": "", "nmag": m_lhds[i], "god": sgod, "mec": smec })

  sort_w_lhds()



  ////onClick={() => props.Setsotr(ar.sotr)}>

  let srows = w_lhds.map((ar) => {
    //alert(ar.sotr)

    if (ar.sotr > 0 && (ar.d_otp || ar.s_otp))
      return (
        <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec + '@' + ar.nmag} className="tr_lhds tr_otp" onClick={init_lhd}>
          <td className="tab">{ar.sotr}  </td>
          <td className="fio">{ar.fio}</td>
          <td className="dolg">{ar.dolg}  </td>
          <td className="nmag">{ar.nmag}  </td>

          <td className="god">{ar.god}</td>
          <td className="mec">{ar.mec}</td>
        </tr>
      )
    else
      return (
        <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec + '@' + ar.nmag} className="tr_lhds" onClick={init_lhd}>
          <td className="tab">{ar.sotr}  </td>
          <td className="fio">{ar.fio}</td>
          <td className="dolg">{ar.dolg}  </td>
          <td className="nmag">{ar.nmag}  </td>

          <td className="god">{ar.god}</td>
          <td className="mec">{ar.mec}</td>
        </tr>
      )
  })

  let w_lhd_f = ""

  if (slhd.sotr)
    w_lhd_f = <Lhd_sotr lhds={props.lhds} lscs={props.lscs} />
  else
    w_lhd_f = <Lhd_nmag lhds={props.lhds} lscs={props.lscs} />



  return (
    <div style={{ "display": "flex" }}>
      <div className="lhds_t" id="div_lhds_t" >
        <table id="table_lhds_t" >
          <thead>

            <tr >
              <th className="tab" onClick={() => Setnsort(1)}><div>Таб №</div> <div><input className="tab" value={w_sotr} onChange={e => { props.Setssotr(e.target.value) }} /> </div></th>
              <th onClick={() => Setnsort(2)}>Фамилия И.О <div><input display="block" className="ffio" value={sfio} onChange={e => { Setsfio(e.target.value) }} /></div> </th>
              <th onClick={() => Setnsort(3)} >Должность <div><input className="dolg" value={sdolg} onChange={e => { Setsdolg(e.target.value) }} /></div> </th>
              <th onClick={() => Setnsort(4)} >Подр <div><input className="nmag" value={smag} onChange={e => { Setsmag(e.target.value) }} /></div> </th>

              <th onClick={() => Setnsort(6)}>Год  <div><input className="god" value={sgod} onChange={e => { Setsgod(e.target.value) }} /></div></th>
              <th onClick={() => Setnsort(7)}>Мес <div><input className="mec" value={smec} onChange={e => { Setsmec(e.target.value) }} /></div> </th>
            </tr>
          </thead>
          <tbody >
            {srows}
          </tbody>
        </table>
      </div >
      <div className="lhd_f" id="div_lhd_f_god" >
        <Context.Provider value={slhd}>
          {w_lhd_f}
        </Context.Provider>
      </div>
    </div>
  )

  function sort_w_lhds() {
    if (nsort) {
      w_lhds.sort(function (a, b) {
        if (nsort === 1)
          if ((a.sotr - b.sotr) > 0) return 1; else return -1;
        else
          if (nsort === 2)
            if (a.fio > b.fio) return 1; else return -1;
          else
            if (nsort === 3)
              if (a.dolg > b.dolg) return 1; else return -1;
            else
              if (nsort === 4)
                if (a.nmag > b.nmag) return 1; else return -1;
              else
                if (nsort === 5 || nsort === 6)
                  if ((a.god - b.god) > 0 || ((a.god === b.god) && (a.mec - b.mec) > 0))
                    return 1
                  else
                    return -1


      });
    }
  }
  function init_lhd(e) {
    const pid = e.currentTarget.id
    const psotr = pid.split("@")[0]   //sotr
    const pgod = pid.split("@")[1]    //god
    const pmec = pid.split("@")[2]    //mec
    const pnmag = pid.split("@")[3]    //mag
     
    if (!(psotr == "") && psotr == slhd.sotr)
      return
    if (!(psotr === "")) {
      for (let i = 0; i < w_lhds.length; i++)
        if (w_lhds[i].sotr == psotr && w_lhds[i].god == pgod && w_lhds[i].mec == pmec) {
         // alert('select ' +psotr)
          Setslhd(w_lhds[i])
          break
        }
    }
    else
      for (let i = 0; i < w_lhds.length; i++)
        if (w_lhds[i].nmag == pnmag && w_lhds[i].god == pgod && w_lhds[i].mec == pmec) {
          Setslhd(w_lhds[i])
          break
        }

  }

}
export default Lhds_t;